<template>
  <div class="fex non-scrollable">
    <FileUpload v-if="me?.role?.name === constants.ROLES.ADMIN" ref="societyDocumentSharerUploader"
                choose-label="Importer plusieurs fichiers" upload-label="Téléverser" cancel-label="Annuler"
                custom-upload :multiple="true" accept="application/pdf,image/*"
                @uploader="uploadFile">
      <template #empty>
        <p>Ajoutez vos fichiers PDF ici.</p>
      </template>
    </FileUpload>

    <DataTable :value="societyDocumentsShared" v-model:editingRows="societyDocumentsSharedEdited"
               v-model:selection="societyDocumentsSharedSelected" :loading="loading" :row-style="() => { return { cursor: 'pointer' } }"
               editMode="row" dataKey="id" class="p-datatable-sm mt-4" row-hover
               scrollable scroll-height="flex"
               @row-edit-save="saveFilesData" @row-click="openDocument">
      <template #header>
        <div class="table-header flex flex-row justify-content-between">
          <h5 class="mb-2 md:m-0 p-as-md-center">Documents de la société de portage</h5>
          <Button v-if="me.role.name === constants.ROLES.ADMIN" icon="pi pi-trash" class="p-button-danger w-10rem"
                  label="Delete"
                  :disabled="!societyDocumentsSharedSelected || !societyDocumentsSharedSelected.length"
                  @click="confirmDeleteSelected"/>
        </div>
      </template>
      <Column v-if="me.role.name === constants.ROLES.ADMIN" selectionMode="multiple" style="width: 3rem"/>
      <Column field="title" header="Nom du fichier">
        <template #body="{data}">
          {{ data?.title }}
        </template>
      </Column>
      <Column field="uploaded_on" header="Date d'import">
        <template #body="{data}">
              <span class="uppercase">
                <!--  todo: might use the metadata of the signature, what to do in Validated case  -->
                {{
                  new Date(data?.uploaded_on).toLocaleDateString('fr-FR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  })
                }}
              </span>
        </template>
      </Column>
      <Column field="description" header="Description">
        <template #editor="{ data, field }">
          <Textarea v-model="data[field]" rows="5" cols="30"/>
        </template>
        <template #body="{data}">
              <span>
                {{ data?.description }}
              </span>
        </template>
      </Column>
      <Column :rowEditor="me.role.name === constants.ROLES.ADMIN" style="width:10%; min-width:4rem"
              bodyStyle="text-align:center" class="row-editing-cell"/>
    </DataTable>
    <Dialog v-if="me.role.name === constants.ROLES.ADMIN" v-model:visible="uploadingFilesDialog" :draggable="false" :style="{width: '500px'}" :modal="true"
            content-class="dialog-content" :show-header="false">
      <div class="flex justify-content-between" v-for="key in Object.keys(filesUploading)" :key="key">
        <span>{{ key }}</span>
        <ProgressBar style="width: 50%" :value="filesUploading[key]"></ProgressBar>
      </div>
    </Dialog>
    <dialog-confirm
        v-if="me.role.name === constants.ROLES.ADMIN"
        :is-active="confirmDialog"
        :refuse-action="closeConfirmDialog"
        :validate-action="confirmDialogValidate"
        :title="confirmDialogTitle"
        :text-info="confirmDialogInfo"
        :text-question="confirmDialogQuestion"
    />
    <dialog-file-preview
        dialog-title="Document de la société de portage"
        :is-active="dialogFilePreview" :file-id="fileId"
        delete-button replace-file-button
        @closeDialog="closeFilePreview"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import DialogConfirm from "@/components/DialogConfirm.vue";
import DialogFilePreview from "@/components/DialogFilePreview.vue";

export default {
  name: "SocietyDocumentsSharer",
  components: {DialogFilePreview, DialogConfirm},
  // should save from the component directly ?
  // emits: ['forceSave'],
  data() {
    return {
      loading: false,
      societyDocumentsShared: [],
      societyDocumentsSharedEdited: [],
      societyDocumentsSharedSelected: [],

      filesUploading: {},
      uploadingFilesDialog: false,
      filesUploaded: [],

      dialogFilePreview: false,
      fileId: null,

      confirmDialog: false,
      closeConfirmDialog: () => {
      },
      confirmDialogValidate: () => {
      },
      confirmDialogTitle: '',
      confirmDialogInfo: '',
      confirmDialogQuestion: '',
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      constants: state => state.constants,
      settings: state => state.misc.settings,
    })
  },
  async created() {
    await this.initDocuments()
  },
  methods: {
    async initDocuments() {
      this.loading = true
      this.societyDocumentsSharedSelected = []
      this.societyDocumentsSharedEdited = []
      try {
        this.societyDocumentsShared = await this.$store.dispatch('misc/getCompanyDocumentsShared')
        this.loading = false
      } catch (e) {
        this.loading = true
      }
    },
    async uploadFile(files) {
      this.uploadingFilesDialog = true
      // upload files and description
      for (const file of files.files) {
        let tmp = file.name.split('.')
        tmp.pop()
        tmp.join('')

        const form = new FormData()
        form.append('filename', file.name)
        form.append('file', file, file.name)

        this.filesUploading[file.name] = 0

        const data = {
          'data': form,
          'progressFunction': (progress) => {
            let percentCompleted = Math.round((progress.loaded * 100) / progress.total)
            this.filesUploading[file.name] = percentCompleted
          }
        }
        this.filesUploading = {}
        this.uploadingFilesDialog = false
        this.$refs.societyDocumentSharerUploader.files = []

        const document = (await this.$store.dispatch('file/uploadFile', data)).id

        this.filesUploaded.push(document)
      }
      await this.saveSettings()
      await this.initDocuments()
      // saving manually the company settings
    },
    async saveSettings() {
      await this.$store.dispatch('misc/updateCompanySettings', {
        societyDocumentsShared: [...this.settings.Entreprise.societyDocumentsShared, ...this.filesUploaded]
      })
    },
    async saveFilesData(fileData) {
      if (this.me.role.name === this.constants.ROLES.ADMIN) {
        await this.$store.dispatch('file/updateFileData', {file: fileData.newData})
        await this.initDocuments()
      }
    },
    async openDocument (event) {
      // prevent from getting the document open while being editing the table
      if (this.societyDocumentsSharedEdited.length > 0) return
      this.fileId = event.data.id
      this.dialogFilePreview = true
    },
    async deleteFileSelected() {
      await this.$store.dispatch('file/deleteManyFiles', {keys: this.societyDocumentsSharedSelected.map(documentSelected => documentSelected.id)})
      await this.initDocuments()
    },
    confirmDeleteSelected() {
      this.confirmDialogTitle = 'Suppression de documents'
      this.confirmDialogInfo = 'Si vous validez les documents selectionner seront supprimés'
      this.confirmDialogQuestion = 'Êtes-vous sur de vouloir supprimer ces documents'

      this.closeConfirmDialog = () => {
        this.resetConfirmDialog()
      }
      this.confirmDialogValidate = async () => {
        await this.deleteFileSelected()
        this.resetConfirmDialog()
      }
      this.confirmDialog = true
    },
    resetConfirmDialog() {
      this.confirmDialog = false
      this.closeConfirmDialog = () => {
      }
      this.confirmDialogValidate = () => {
      }
      this.confirmDialogTitle = ''
      this.confirmDialogInfo = ''
      this.confirmDialogQuestion = ''
    },
    closeFilePreview() {
      this.dialogFilePreview = false
    }
  }
}
</script>

<style scoped>
</style>
